/*jshint unused:false*/
window.tcpConfig = {
  restApi: {
    baseUrl: "/member/secure/api/",
    nodeBaseUrl: "/sydmedservices/secure/engagement/member/secure/api/tcp/",
    caching: {
      enabled: true, // see httpInterceptor.js
      excludePathsRegex: "/member/secure/api/" + "(dummy)",
    },
    timeout: 15000,
    utility: {
      log: "utility/log",
      audit: "utility/audit",
    },
    membership: {
      partnerlogin: "/member/:mbrUid/partnerlogin",
    },
    engage: {
      rewards: {
        mhrMilestones: "engage/v1/member/:mbrUid/rewards/milestones",
        anthemHealthRewardsIncentives: "engage/v1/member/:mbrUid/ahrincentives",
        anthemHealthRewardsActivities: "engage/v1/member/:mbrUid/ahractivities",
        getBadges: "engage/v1/member/:mbrUid/badges",
        mhrHowtoEarn: "engage/v1/member/:mbrUid/rewards/programs",
        mhrActivities:
          "engage/v1/member/:mbrUid/rewards/milestones/:milestoneCd/activities",
      },
      devices: {
        getActivity: "engage/member/:mbrUid/deviceIntegration/metrics",
        getActivitySummary: "engage/member/:mbrUid/deviceIntegration/summary",
        getDevices: "engage/member/:mbrUid/deviceIntegration/deviceTypes",
        updateDeviceMapping:
          "engage/member/:mbrUid/deviceIntegration/metricDeviceTypeMapping",
      },
      plans: {
        getPlans: "engage/v2/member/:mbrUid/actionplans",
        updPlans: "engage/member/:mbrUid/actionplans",
        actionPlan: "engage/member/:mbrUid/actionplans/:actionPlanId",
        getPlanInfo: "engage/member/:mbrUid/actionplans/:actionplanId",
        getStep: "engage/member/:mbrUid/actionplans/tasks/taskinfo",
        activity: "engage/member/:mbrUid/actionplans/activities/activity",
        getArticle: "engage/articles/:articleId",
        getRecipe: "engage/recipes/:recipeId",
        getChallenge: "engage/challenges/:challengeId",
        getMealPlan: "engage/mealplans/:mealplanId",
        contentConsume: "engage/member/:mbrUid/actionplans/content/consumed",
      },
      content: {
        getList: 'engage/member/:mbrUid/contents',
        getDetail: 'engage/member/:mbrUid/hwcontent/:contentId',
        update: 'engage/member/:mbrUid/contents',
        search: 'engage/member/:mbrUid/search'
      },
      healthNewsFeed: {
        getSavedTopics: 'engage/member/:mbrUid/healthnews/savedlist',
        getNewContent: 'engage/member/:mbrUid/healthnews/newcontent',
        getCategories: 'engage/member/:mbrUid/healthnews/categories',
        getTrendingTopics: 'engage/member/:mbrUid/healthnews/trending',
        getProviderTopics: 'engage/member/:mbrUid/healthnews/provider',
        getRecommendedTopics: 'engage/member/:mbrUid/healthnews/recommended'
      },
      interestQuestions: {
        get: 'engage/member/:mbrUid/interests',
        update: 'engage/member/:mbrUid/interests'
      }
    },
    graphQl: {
      engage: "engage/graphql",
    },
  },
  web: {
    cxReplay: {
      includePathsRegex: "^(billpay).*$",
    },
    session: {
      idle: 660000, //user idle time limit
      keepalive: true, //enable keepalive hartbeat ping to web server
      keepaliveTimeout: 60000, // keepalive ping interval
      logOutTimer: 30000, // milliseconds time until logout in confirm modal, 30 sec
      timeOutTimer: 120000, // milliseconds time until logout in confirm modal, 2 min
      jwtTimeout: 3300000, // little less than 1h
      jwtTimeoutTimer: 300000,
      smSessionRefreshTimer: 600000,
      getKeepAliveSessionTimeoutUrl: "sitemanager/eportal.htm",
      keepAliveSMSessionApiUrl: "profile/member/session/alive",
    },
    cookie: {
      setDomain: true,
      setPath: true,
    },
  },
  logging: {
    batchTimerInSeconds: 10,
    batchItemsMax: 100,
    logLevelApi: "INFO",
    enable: true,
  },
  audit: {
    batchTimerInSeconds: 10,
    batchItemsMax: 100,
  },
  httpInterceptor: {
    enable: true,
    request: true,
    response: true,
    responseError: true,
    contentType: true,
    authToken: true,
    urlHash: true,
    wcsUrlParams: true,
    trimSpaces: false,
    passFlow: true,
    passBrandCd: true,
  },
  wcs: {
    imageUrl: "/sydmedservices/public/images/dam/",
    engageImageUrl: "/sydmedservices/public/images/",
    videoUrl: "/sydmedservices/public/content/ENGAGE_SHARED_VIDEO_CONTENT"
  },
  environment: "local",
};

window.tcpConfigSub = window.tcpConfigSub || {};
